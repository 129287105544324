@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  
  body {
    @apply bg-white dark:bg-dark transition-colors duration-200;
  }
}

/* Text Animation Keyframes */
@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: currentColor;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUpFade {
  0% {
    opacity: 0;
    transform: translateY(60px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes gradientSlide {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes letterSpacing {
  0% {
    letter-spacing: -10px;
    opacity: 0;
    filter: blur(10px);
  }
  25% {
    opacity: 1;
    filter: blur(0);
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes textReveal {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
    transform: translateY(0);
  }
}

/* Text Animation Classes */
.animate-reveal {
  animation: reveal 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-slide-in {
  animation: slideIn 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-slide-out {
  animation: slideOut 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-typewriter {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid;
  animation: typewriter 2.5s steps(40, end), blink 0.75s step-end infinite;
}

.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.animate-delay-1 {
  animation-delay: 0.2s;
}

.animate-delay-2 {
  animation-delay: 0.4s;
}

.animate-text-reveal {
  animation: textReveal 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-letter-spacing {
  animation: letterSpacing 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-gradient {
  background-size: 200% auto;
  animation: gradientSlide 4s linear infinite;
}

.animate-slide-up-fade {
  animation: slideUpFade 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate-delay-75 {
  animation-delay: 75ms;
}

.animate-delay-150 {
  animation-delay: 150ms;
}

.animate-delay-300 {
  animation-delay: 300ms;
}

.animate-delay-500 {
  animation-delay: 500ms;
}

@layer components {
  .nav-link {
    @apply text-gray-700 dark:text-gray-300 hover:text-primary dark:hover:text-primary transition-colors duration-300 relative;
  }

  .nav-link::after {
    content: '';
    @apply absolute left-0 bottom-0 w-0 h-0.5 bg-primary transition-all duration-300;
  }

  .nav-link:hover::after {
    @apply w-full;
  }

  .mobile-nav-link {
    @apply text-gray-700 dark:text-gray-300 hover:text-primary dark:hover:text-primary px-3 py-2 rounded-md text-base font-medium transition-colors duration-300;
  }

  .glass-effect {
    @apply bg-white/70 backdrop-blur-lg border border-white/20;
  }
  
  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary;
  }
  
  .hover-card {
    @apply transition-all duration-300 hover:shadow-lg hover:-translate-y-1;
  }

  .pattern-grid {
    background-image: linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px);
    background-size: 20px 20px;
    mask-image: linear-gradient(180deg, white, transparent);
  }

  .dark .pattern-grid {
    background-image: linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px);
  }
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

/* Modern Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-b from-primary to-secondary rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply from-primary/90 to-secondary/90;
}

/* Smooth transitions */
a, button {
  @apply transition-all duration-300;
}

/* Modern card effects */
.modern-card {
  @apply relative overflow-hidden rounded-2xl transition-all duration-300;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.dark .modern-card {
  background: rgba(15, 23, 42, 0.7); /* dark mode background */
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modern-card:hover {
  @apply shadow-xl transform -translate-y-2;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.dark .modern-card:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Section animations */
.section-animate {
  @apply opacity-0 translate-y-10 transition-all duration-700;
}

.section-animate.animate {
  @apply opacity-100 translate-y-0;
}

/* Gradient borders */
.gradient-border {
  position: relative;
  border: double 1px transparent;
  border-radius: 1rem;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right, theme('colors.primary'), theme('colors.secondary'));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* Dark mode specific styles */
.dark {
  color-scheme: dark;
}

/* Form input dark mode styles */
.dark input,
.dark textarea {
  @apply bg-gray-800 border-gray-700 text-white;
}

.dark input::placeholder,
.dark textarea::placeholder {
  @apply text-gray-400;
}

/* Button dark mode styles */
.dark button {
  @apply bg-opacity-90 hover:bg-opacity-100;
}

/* Card and container dark mode styles */
.dark .bg-white {
  @apply bg-gray-800;
}

.dark .border-gray-200 {
  @apply border-gray-700;
}

/* Text colors for dark mode */
.dark .text-gray-600,
.dark .text-gray-700,
.dark .text-gray-800,
.dark .text-gray-900 {
  @apply text-gray-300;
}

/* Background colors for dark mode */
.dark .bg-gray-50,
.dark .bg-gray-100,
.dark .bg-gray-200 {
  @apply bg-gray-800;
}

/* Glass effect for dark mode */
.dark .glass {
  @apply bg-gray-800/30 backdrop-blur-md border-gray-700/50;
}

/* Glass Effect Styles */
.backdrop-blur-md {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

/* Background Animations */
@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-20px) rotate(5deg);
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drift {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(50px, 20px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    filter: blur(40px);
  }
  50% {
    opacity: 0.8;
    filter: blur(60px);
  }
}

.animate-sparkle {
  animation: sparkle 3s ease-in-out infinite;
}

.animate-drift {
  animation: drift 15s linear infinite;
}

.animate-glow {
  animation: glow 4s ease-in-out infinite;
}

.delay-1 { animation-delay: 1s; }
.delay-2 { animation-delay: 2s; }
.delay-3 { animation-delay: 3s; }
.delay-4 { animation-delay: 4s; }
.delay-5 { animation-delay: 5s; }

/* Blob Animation */
@keyframes blob {
  0%, 100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-pulse-slow {
  animation: pulse 4s ease-in-out infinite;
}

.animate-wave {
  animation: wave 8s ease-in-out infinite;
}

.animate-blob {
  animation: blob 8s ease-in-out infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

/* Gradient Background */
.gradient-bg {
  background: linear-gradient(
    45deg,
    rgba(var(--primary-rgb), 0.1) 0%,
    rgba(var(--secondary-rgb), 0.1) 100%
  );
  filter: blur(100px);
}

/* Logo Slider Animation */
@keyframes slide-track {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-slide-track {
  animation: slide-track 30s linear infinite;
}

/* Smooth hover pause */
.animate-slide-track:hover {
  animation-play-state: paused;
}

/* Snow Animation */
@keyframes snowfall {
  0% {
    transform: translateY(-100%) translateX(-50%) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(100vh) translateX(50%) rotate(360deg);
    opacity: 0;
  }
}

.snowflake {
  position: fixed;
  color: white;
  font-size: 20px;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 1;
  user-select: none;
}

.snowflake:nth-child(3n) { content: "❅"; }
.snowflake:nth-child(3n+1) { content: "❆"; }
.snowflake:nth-child(3n+2) { content: "❉"; }

.snowflake:nth-child(1) { left: 10%; animation: snowfall 10s linear infinite; animation-delay: -1s; }
.snowflake:nth-child(2) { left: 20%; animation: snowfall 12s linear infinite; animation-delay: -2s; }
.snowflake:nth-child(3) { left: 30%; animation: snowfall 8s linear infinite; animation-delay: -3s; }
.snowflake:nth-child(4) { left: 40%; animation: snowfall 11s linear infinite; animation-delay: -4s; }
.snowflake:nth-child(5) { left: 50%; animation: snowfall 9s linear infinite; animation-delay: -5s; }
.snowflake:nth-child(6) { left: 60%; animation: snowfall 13s linear infinite; animation-delay: -6s; }
.snowflake:nth-child(7) { left: 70%; animation: snowfall 7s linear infinite; animation-delay: -7s; }
.snowflake:nth-child(8) { left: 80%; animation: snowfall 10s linear infinite; animation-delay: -8s; }
.snowflake:nth-child(9) { left: 90%; animation: snowfall 12s linear infinite; animation-delay: -9s; }
.snowflake:nth-child(10) { left: 95%; animation: snowfall 8s linear infinite; animation-delay: -10s; }

/* New Year Animations */
@keyframes firework {
  0% {
    transform: scale(0) translateY(0) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(-100px) rotate(360deg);
    opacity: 0;
  }
}

@keyframes confetti {
  0% {
    transform: translateY(-10px) rotateZ(0deg);
    opacity: 1;
  }
  25% {
    transform: translateY(25vh) rotateZ(90deg);
    opacity: 1;
  }
  50% {
    transform: translateY(50vh) rotateZ(180deg);
    opacity: 0.8;
  }
  75% {
    transform: translateY(75vh) rotateZ(270deg);
    opacity: 0.6;
  }
  100% {
    transform: translateY(100vh) rotateZ(360deg);
    opacity: 0;
  }
}

@keyframes sparkle {
  0%, 100% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 1;
  }
}

.animate-firework {
  animation: firework 2s ease-out infinite;
}

.animate-confetti {
  animation: confetti 3s ease-in-out infinite;
}

.animate-sparkle {
  animation: sparkle 2s ease-in-out infinite;
}

@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-gradient-x {
  background-size: 200% 200%;
  animation: gradient-x 4s linear infinite;
}

.animate-fade-in-up {
  animation: fade-in-up 0.6s ease-out forwards;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}
